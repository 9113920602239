"use client";

import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  List,
  ListItem,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  useToast,
  InputRightElement,
} from "@chakra-ui/react";
import img from "../../assets/jar.png";
import { FaSortAmountUp } from "react-icons/fa";
import { useEffect, useState } from "react";
import { RiLockPasswordFill } from "react-icons/ri";
import { getUserProfile, getWalletInfo, withdrawAmount } from "../../api/user";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import {  useNavigate } from "react-router-dom";

export default function Simple() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");
  const [userProfile, setUserProfile] = useState(false);
  const [walletInfo, setWalletInfo] = useState(null);
  const navigate = useNavigate();

  async function userInfo() {
    try {
      setLoading(true);
      getUserProfile().then((data1) => {
        getWalletInfo().then((data2) => {
          setWalletInfo(data2);
          setLoading(false);
          console.log("wallet Info", data2);
        });
        setUserProfile(data1);
        console.log("userProfile", userProfile);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: "Something Went Wrong",
        description: "Contact Support",
        status: "error",
        position: "top",
      });
    }
  }
  useEffect(() => {
    userInfo();
  }, []);

  function setAllAmount() {
    setAmount(userProfile.wallet_amount);
  }

  async function handleSubmit() {
    try {
      if (
        (userProfile.wallet_amount >= amount && password !== "") ||
        amount !== ""
      ) {
        setLoading(true);
        console.log("walletInfo", amount);
        await withdrawAmount(amount, password);
        // userInfo();
        setLoading(false);
        return toast({
          title: "Withdrawal Request Sent",
          status: "success",
          position: "top",
          isClosable: true,
        });
      } else {
        return toast({
          title: "Fill All The Feilds",
          description: "",
          status: "error",
          position: "top",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: "Something Went Wrong",
        description:
          error?.message || error?.error?.message || "Contact Support",
        status: "error",
        position: "top",
      });
    }
  }
  return (
    <>
      {!loading && userProfile ? (
        <Container maxW={"7xl"} color={"gray.300"}>
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 6, md: 6 }}
            py={{ base: 18, md: 28 }}
            pr={10}
          >
            <VStack>
              <Image
                rounded={"md"}
                alt={"product image"}
                src={img}
                fit={"fill"}
                align={"center"}
                w={"60%"}
                h={{ base: "60%", sm: "60%", lg: "40%" }}
                ml={"5rem"}
              />
              <Heading>Account Balance</Heading>
              <Text textAlign={"center"}>
                {`${userProfile.wallet_amount} USDC` || ""}
              </Text>
            </VStack>
            <Stack spacing={{ base: 6, md: 10 }}>
              <HStack>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                  marginRight={"3"}
                >
                  Withdrawal Method
                </Heading>{" "}
                <MdOutlineAccessTimeFilled
                  onClick={() => navigate("/user/withdrawal-history")}
                  size={"20"}
                />
              </HStack>

              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={"column"}
                divider={<StackDivider borderColor={"gray.600"} />}
              >
                {/* <Divider
              borderColor={useColorModeValue("gray.200", "gray.600")}
              mt={4}
              size={"full"}
            /> */}
                <Container p={0} m={0} >
                  <HStack spacing={{ base: 4, sm: 6 }}>
                    <Text fontSize={"lg"}>Wallet Name </Text>
                    <Text fontSize={"lg"}>
                      {walletInfo.wallet_name || ""}
                    </Text>
                  </HStack>
                  <HStack spacing={{ base: 4, sm: 6 }}>
                    <Text fontSize={"lg"}>Crypto Wallet Address </Text>
                    <Text fontSize={"lg"}>
                      {walletInfo.wallet_address || ""}
                    </Text>
                  </HStack>
                  <HStack spacing={{ base: 4, sm: 6 }}>
                    <Text fontSize={"lg"}>Phone Number </Text>
                    <Text fontSize={"lg"}>
                      {walletInfo.wallet_phone_number || ""}
                    </Text>
                  </HStack>
                </Container>
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={"yellow.500"}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    Withdrawal Method
                  </Text>

                  <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
                    <List spacing={4}>
                      <ListItem>1. Fill in the withdrawal amount </ListItem>
                      <ListItem>
                        2. Fill in the withdrawal password
                      </ListItem>{" "}
                      <ListItem>
                        You may proceed to the withdrawal now! Kindly consult
                        our agent support if you have any
                      </ListItem>
                    </List>
                  </SimpleGrid>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={"yellow.500"}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    Withdrawal Here
                  </Text>
                  <VStack>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <FaSortAmountUp color="gray.900" />
                      </InputLeftElement>
                      <Input
                        placeholder="Amount"
                        bg={"gray.100"}
                        type="number"
                        border={0}
                        color={"gray.700"}
                        _placeholder={{
                          color: "gray.500",
                        }}
                        value={amount}
                        onChange={(e) =>
                          userProfile.wallet_amount >= e.target.value
                            ? setAmount(e.target.value)
                            : ""
                        }
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          h="1.75rem"
                          size="sm"
                          variant={"ghost"}
                          color={"gray.400"}
                          onClick={setAllAmount}
                        >
                          All
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <RiLockPasswordFill color="gray.900" />
                      </InputLeftElement>
                      <Input
                        bg={"gray.100"}
                        type="password"
                        border={0}
                        placeholder="Password"
                        color={"gray.700"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </VStack>
                </Box>
              </Stack>

              {walletInfo && <Button
                rounded={"20px"}
                //  w={"50%"}
                size={"lg"}
                py={"0"}
                bg={"blue.700"}
                color={"gray.300"}
                textTransform={"uppercase"}
                _hover={{
                  transform: "translateY(2px)",
                  boxShadow: "lg",
                  bg: `gray.300`,
                  color: "blue.900",
                }}
                onClick={handleSubmit}
              >
                Withdrawal Here
              </Button>}
            </Stack>
          </SimpleGrid>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
