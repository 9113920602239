import { Box, Container, Heading, SimpleGrid, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import RechargeCard from "./component/rechargeCard";
import Warning from "../../shared/warning/warning";
import { withdrawalHistory } from "../../api/user";

function RechargeHistory() {
  const toast = useToast();
  const [data,setData] = useState([])
  const [loading,setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true);
    let res
    try {
        withdrawalHistory('deposit').then(res=>{
          console.log(res)
        setData(res);
      }).catch(error=>{
          setLoading(false);
          toast({
            title: "Error While Loading Data",
            description: error.message,
            status: "error",
            position: "top",
          });
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error While Loading Data",
        description: error.message,
        status: "error",
        position: "top",
      });
    }
  },[])

  return (
    <Container maxW={"6xl"} pt={"2rem"} color={"gray.300"}>
      {data && data.length !== 0 ? (
        <>
          <Heading>Recharge History</Heading>
          <SimpleGrid mt={7} columns={{ base: 2, md: 4, sm: 3 }} spacing={2}>
            {data?.length !== 0 && data?.map((e, i) => {
              return <RechargeCard key={i} data={e} />;
            })}
          </SimpleGrid>
        </>
      ) : (
        <>
          <Warning
            header={"No Data Found !"}
            desc={"No data was found please try again later."}
          />
        </>
      )}
    </Container>
  );
}

export default RechargeHistory;
