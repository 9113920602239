import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Image,
  useToast,
} from "@chakra-ui/react";
import image from "../../assets/logo.png";
import SignUpForm from "./components/signupForm";
import Blur from "./components/blur";
import { useState } from "react";
import { signUpValidation } from "./components/signUpValidation";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../api/signUp.js";

export default function JoinOurTeam() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirm_password: "",
    withdrawal_password: "",
    confirm_withdrawal_password: "",
    number: "",
    first_name: "",
    last_name: "",
    invitationCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // signUpValidation(formData);
      const res = await signUp(formData);
      setLoading(false);
      if (res.status === 201) {
        toast({
          title: "Sign Up Successful",
          status: "success",
          position: "top",
          isClosable: true,
        });
        navigate("/user/home");
      } else {
        toast({
          title: "Sign Up Failed",
          description: res.description,
          status: "error",
          position: "top",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: "Sign Up Failed",
        description: error.message || error.error.message,
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <Box position={"relative"} background={"gray.700"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 8, sm: 18, lg: 28 }}
      >
        <Stack spacing={{ base: null, md: 20 }}>
        <Image
              ml={{base:"",sm:"100px",lg:"80px"}}
              mb={{ base: "-50px", sm: "-100px", lg: "-130px" }}
              src={image}
              boxSize="100px"
              objectFit="fit"
              align={"center"}
              w={"70%"}
              h={{ base: "70%", sm: "200px", lg: "300px" }}
            ></Image>
          <Heading
            lineHeight={1.1}
            fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
            color={"gray.50"}
            // zIndex={10}
          >
            Watch Real Movies{"  "}
            <Text
              as={"span"}
              bgGradient="linear(to-r, red.400,pink.400)"
              bgClip="text"
            >
              &
            </Text>{" "}
            Earn By Rating Them
          </Heading>
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              Join Now
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {" "}
                !
              </Text>
              <Text
                mt={4}
                color={"gray.500"}
                fontSize={{ base: "sm", sm: "lg" }}
              >
                Sign up an account
              </Text>
            </Heading>
          </Stack>
          <Box as={"form"} mt={3}>
            <SignUpForm formData={formData} handleChange={handleChange} />
            <Text mt={4}>
              Already have an account ?{" "}
              <Link to="/">
                <Text display={"inline"} color={"blue.500"}>
                  Sign-in
                </Text>
              </Link>
            </Text>
            <Button
              fontFamily={"heading"}
              mt={5}
              w={"full"}
              bg={"blue.900"}
              color={"gray.50"}
              _hover={{
                bg: "gray.300",
                color: "blue.900",
                boxShadow: "x2",
              }}
              onClick={handleSubmit}
              isLoading={loading}
            >
              Sign Up
            </Button>
          </Box>
        </Stack>
      </Container>
      <Blur
        position={"absolute"}
        top={-10}
        left={-10}
        style={{ filter: "blur(70px)" }}
      />
    </Box>
  );
}
