import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaStar } from "react-icons/fa";

export default function RechargeModal({
  isOpen,
  onOpen,
  onClose,
  getOrderInfoInit,
}) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="gray.300" color={"blue.900"}>
          <ModalHeader>Recharge Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>To Recharge Your Account Contact Support.</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              bg={"blue.900"}
              color={"gray.300"}
              mr={3}
              onClick={onClose}
              _hover={{
                transform: "translateY(2px)",
                boxShadow: "lg",
                bg: `blue.900`,
                color: "gray.300",
              }}
            >
              Okay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
