import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import PanelTab from "./tabPanel" 
function MainTabs({data}) {
  return (
    <Tabs align={"center"} variant="soft-rounded">
      <TabList mb="2em" mt="2em" color={"gray.300"}>
        <Tab color={"gray.300"}>All</Tab>
        <Tab color={"gray.300"}>Pending</Tab>
        <Tab color={"gray.300"}>Accepted</Tab>
        <Tab color={"gray.300"}>Rejected</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <PanelTab data={data} type={"all"} />
        </TabPanel>
        <TabPanel>
          <PanelTab data={data} type={"pending"} />
        </TabPanel>
        <TabPanel>
          <PanelTab data={data} type={"accepted"} />
        </TabPanel>
        <TabPanel>
          <PanelTab data={data} type={"rejected"} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default MainTabs;
