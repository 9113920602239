import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import WithdrawalCard from "./withdrawalCard";
import Warning from "../../../shared/warning/warning";

function TabPanel({ data,type }) {
  console.log("pending", data);
  return (
    <SimpleGrid minChildWidth="277px" spacing="2em">
      {(!data && data?.length === 0) && (
        <Warning
          header={"No Data Found !"}
          desc={"No data was found please try again later."}
        />
      )}
      {data.length > 0 &&
        data.map((e, i) => {
          return (type === "all" || e.status === type )? (
              <Box>
                <WithdrawalCard key={i} data={e} />
              </Box>
            ) : (
              <></>
            );
          }
        )}
    </SimpleGrid>
  );
}

export default TabPanel;
