import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import MovieDetailCard from "./movieDetailCard";
import Warning from "../../../shared/warning/warning";

function pendingOrders({ data }) {
  console.log("pending", data);
  return (
    <SimpleGrid minChildWidth="240px" spacing="2em">
      {(!data && data?.length === 0) && (
        <Warning
          header={"No Data Found !"}
          desc={"No data was found please try again later."}
        />
      )}
      {data.length > 0 &&
        data.map((e, i) => {
          return (e.rating.status !== "rated" && e.rating.status !== "hold") ? (
              <Box>
                <MovieDetailCard key={i} data={e} />
              </Box>
            ) : (
              <></>
            );
          }
        )}
    </SimpleGrid>
  );
}

export default pendingOrders;
