import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import img from "../../assets/jar.png";
import { FaSortAmountUp } from "react-icons/fa";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { getUserProfile } from "../../api/user";
import AmountCard from "./components/amountCard";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RechargeModal from "./components/rechargeModal";

function Recharge() {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("0");
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function userInfo() {
    try {
      setLoading(true);
      getUserProfile().then((data1) => {
        setAmount(data1.wallet_amount);
        // console.log("userProfile", data1.wallet_amount);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: "Something Went Wrong",
        description: "Contact Support",
        status: "error",
        position: "top",
      });
    }
  }
  useEffect(() => {
    userInfo();
  }, []);
  const amounts = [50, 100, 200, 1000, 3000, 5000];

  const handleRecharge = () => {
    onOpen();
  };
  return (
    <>
      <RechargeModal onClose={onClose} isOpen={isOpen} />
      <Container maxW={"7xl"} color={"gray.300"}>
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 6, md: 6 }}
          py={{ base: 18, md: 28 }}
          pr={10}
        >
          <VStack>
            <Image
              rounded={"md"}
              alt={"product image"}
              src={img}
              fit={"fill"}
              align={"center"}
              w={"60%"}
              h={{ base: "60%", sm: "60%", lg: "40%" }}
              ml={"5rem"}
            />
            <Heading>Account Balance</Heading>
            <Text textAlign={"center"}>{`${amount} USDC` || ""}</Text>
          </VStack>
          <Stack
            spacing={{ base: 6, md: 10 }}
            alignItems={{ base: "center", md: "flex-start" }}
            justifyContent={{ base: "center", md: "flex-start" }}
          >
            <HStack>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                marginRight={"3"}
              >
                Deposit Amount
              </Heading>{" "}
              <MdOutlineAccessTimeFilled
                onClick={() => navigate("/user/recharge-history")}
                size={"20"}
              />
            </HStack>

            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={"column"}
              divider={<StackDivider borderColor={"gray.600"} />}
              alignItems={{ base: "center", md: "flex-start" }}
              justifyContent={{ base: "center", md: "flex-start" }}
            >
              <Box>
                <SimpleGrid columns={{ base: 3, md: 3 }} spacing={2}>
                  {amounts.map((e, i) => {
                    return <AmountCard key={i} amount={e} />;
                  })}
                </SimpleGrid>
              </Box>
              <Box>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={"yellow.500"}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={"4"}
                >
                  Recharge Amount
                </Text>
                <VStack>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <FaSortAmountUp color="gray.900" />
                    </InputLeftElement>
                    <Input
                      placeholder="Amount"
                      bg={"gray.100"}
                      type="number"
                      border={0}
                      color={"gray.700"}
                      _placeholder={{
                        color: "gray.500",
                      }}
                    />
                  </InputGroup>
                </VStack>
              </Box>
            </Stack>

            <Button
              rounded={"20px"}
              //  w={"50%"}
              size={"lg"}
              py={"0"}
              bg={"blue.700"}
              color={"gray.300"}
              textTransform={"uppercase"}
              _hover={{
                transform: "translateY(2px)",
                boxShadow: "lg",
                bg: `gray.300`,
                color: "blue.900",
              }}
              onClick={handleRecharge}
            >
              Recharge
            </Button>
          </Stack>
        </SimpleGrid>
      </Container>
    </>
  );
}

export default Recharge;
