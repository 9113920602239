// theme.js
import { extendTheme, keyframes, useColorModeValue } from "@chakra-ui/react";

// Define the fade-in keyframes
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
// Define the typing effect keyframes
const typing = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const customTheme = extendTheme({
  components: {
    Heading: {
      baseStyle: (props) => ({
        color: props.colorMode === "light" ? "yellow.500" : "yellow.300",
      }),
    },
    Text: {
      baseStyle: {
        //animation: `${typing} 1.0s steps(40, end) forwards`, // Adjust timing here
        //   overflow: "hidden", // Ensure text overflow is hidden
        //   display: "inline-block", // Keep the display inline-block
        //   whiteSpace: "nowrap", // Prevent text from wrapping
        //   width: "100%", // Ensure it expands to full width
      },
    },
  },
  Button: {
    baseStyle: (props) => ({
      rounded: "20px",
      w: "50%",
      size: "lg",
      py: "0",
      bg: props.colorMode === "light" ? "blue.600" : "blue.700",
      color: props.colorMode === "light" ? "white" : "gray.900",
      textTransform: "uppercase",
      _hover: {
        transform: "translateY(2px)",
        boxShadow: "lg",
        bg: "gray.300",
        color: "blue.900",
      },
    }),
  },
});

export default customTheme;
