import { createBrowserRouter } from "react-router-dom";
import Home from "./Pages/home";
import Login from "./Pages/login";
import SignUp from "./Pages/signup";
import Order from "./Pages/order";
import OrderHistory from "./Pages/orderHistory";
import UserRootLayout from "./layout/UserRootLayout";
import UserAuthLayout from "./layout/UserAuthLayout";
import Profile from "./Pages/profile";
import Membership from "./Pages/membership";
import About from "./Pages/about";
import Certificate from "./Pages/certificate";
import Contact from "./Pages/contactus";
import ProtectedRoute from "./shared/protectedRoutes";
import Withdraw from "./Pages/withdraw"
import WalletInfo from "./Pages/withdrawInfo"
import TermsAndconditions from "./Pages/TermsAndConditions";
import FAQs from "./Pages/faqs";
import WithdrawalHistory from "./Pages/withdrawalHistory"
import Recharge from "./Pages/recharge";
import RechargeHistory from "./Pages/rechargeHistory";

export const routes = [
  {
    path: "/user",
    element: <UserRootLayout />,
    children: [
      {
        path: "recharge-history",
        element: (
          <>
            <RechargeHistory />
          </>
        ),
      },
      {
        path: "recharge",
        element: (
          <>
            <Recharge />
          </>
        ),
      },
      {
        path: "orders",
        element: (
          <>
            <Order />
          </>
        ),
      },
      {
        path: "withdrawal-history",
        element: (
          <>
            <WithdrawalHistory />
          </>
        ),
      },
      {
        path: "T&C",
        element: (
          <>
            <TermsAndconditions />
          </>
        ),
      },
      {
        path: "FAQs",
        element: (
          <>
            <FAQs />
          </>
        ),
      },
      {
        path: "home",
        element: (
          <>
            <Home />
          </>
        ),
      },
      {
        path: "order-history",
        element: (
          <>
            <OrderHistory />
          </>
        ),
      },
      {
        path: "profile",
        element: (
          <>
            <Profile />
          </>
        ),
      },
      {
        path: "certificate",
        element: (
          <>
            <Certificate />
          </>
        ),
      },
      {
        path: "GetInTouch",
        element: (
          <>
            <Contact />
          </>
        ),
      },
      {
        path: "membership",
        element: (
          <>
            <Membership />
          </>
        ),
      },
      {
        path: "about",
        element: (
          <>
            <About />
          </>
        ),
      },
      {
        path: "withdraw",
        element: (
          <>
            <Withdraw />
          </>
        ),
      },
      {
        path: "wallet-info",
        element: (
          <>
            <WalletInfo />
          </>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <UserAuthLayout />,
    children: [
      {
        path: "/sign-up",
        element: (
          <>
            <SignUp />
          </>
        ),
      },
      {
        path: "/",
        element: (
          <>
            <Login />
          </>
        ),
      },
    ],
  },
]

export default createBrowserRouter(routes);
