import {
  Flex,
  Circle,
  Box,
  Image,
  Badge,
  useColorModeValue,
  Icon,
  chakra,
  Tooltip,
  Tag,
  Text,
} from "@chakra-ui/react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import bookingImage from "../../../assets/5329598.jpg";

const data = {
  isNew: true,
  imageURL:
    "https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=4600&q=80",
  name: "Wayfarer Classic",
  price: 4.5,
  rating: 4.2,
  numReviews: 34,
};

function Rating({ rating, numReviews }) {
  return (
    <Box display="flex" alignItems="center">
      {Array(5)
        .fill("")
        .map((_, i) => {
          const roundedRating = Math.round(rating * 2) / 2;
          if (roundedRating - i >= 1) {
            return (
              <BsStarFill
                key={i}
                style={{ marginLeft: "1" }}
                color={i < rating ? "teal.500" : "gray.300"}
              />
            );
          }
          if (roundedRating - i === 0.5) {
            return <BsStarHalf key={i} style={{ marginLeft: "1" }} />;
          }
          return <BsStar key={i} style={{ marginLeft: "1" }} />;
        })}
    </Box>
  );
}

function MovieDetailCard({ data }) {
  return (
    <Flex p={50} w="full" alignItems="center" justifyContent="center">
      <Box
        bg={useColorModeValue("white", "gray.800")}
        minW={"base"}
        maxWidth={"sm"}
        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        position="relative"
      >
        {data?.isNew && (
          <Circle
            size="10px"
            position="absolute"
            top={2}
            right={2}
            bg="red.200"
          />
        )}

        <Image
          src={data?.movie_image || bookingImage}
          alt={`Picture of ${data?.movie_name}`}
          roundedTop="lg"
          height="210px"
          width="100%"
          objectFit="fit" // Ensure the image covers the full area without distortion
        />

        <Box p="6">
          <Box display="flex" alignItems="baseline">
            {data?.isNew && (
              <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="red">
                New
              </Badge>
            )}
          </Box>
          <Flex mt="1" alignContent="center">
            <Box
              fontSize="2xl"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {data?.movie_name?.substring(0, 10)}
            </Box>
            {data?.rating?.status === "rated" ? (
              <Tag
                alignSelf={"center"}
                ml={2}
                variant="solid"
                colorScheme="teal"
              >
                Completed
              </Tag>
            ) : data?.rating?.status === "hold" ? (
              <Tag
                alignSelf={"center"}
                ml={2}
                variant="solid"
                colorScheme="yellow"
              >
                Hold
              </Tag>
            ) : (
              <Tag
                alignSelf={"center"}
                ml={2}
                variant="solid"
                colorScheme="red"
              >
                Pending
              </Tag>
            )}
          </Flex>
          <Flex mt="1" alignContent="center">
            <Box fontSize="lg">Price (USDC)</Box>
            <Text ml={2}>{data?.movie_price}</Text>
          </Flex>
          <Flex mt="1" alignContent="center">
            <Box fontSize="lg">Commision (USDC)</Box>
            <Text ml={2}>{data?.rating?.commission_amount}</Text>
          </Flex>
          <Flex justifyContent="space-between" alignContent="center">
            <Rating
              rating={data?.rating?.user_rating}
              numReviews={data?.numReviews}
            />
            <Box
              fontSize="md"
              ml={7}
              color={useColorModeValue("gray.800", "white")}
            >
              {new Date(data?.rating?.createdAt).toLocaleDateString()}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default MovieDetailCard;
