import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Divider,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown } from "react-icons/fi";
import { FaAddressCard, FaWallet } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import { HiHome } from "react-icons/hi2";
import { HiTrendingUp } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { MdCardMembership } from "react-icons/md";
import { PiCertificateFill } from "react-icons/pi";
import { AiFillMessage } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import Logo from "../../assets/logo.png";
import userAvatar from "../../assets/user.png";
import { FaUserCircle } from "react-icons/fa";
import { removeTawkTo } from "../../utils/tawkTo";

const LinkItems = [
  { name: "Home", icon: HiHome, link: "/user/home" },
  { name: "Start your career", icon: HiTrendingUp, link: "/user/orders" },
  { name: "History", icon: FaHistory, link: "/user/order-history" },
  {
    name: "Recharge",
    icon: MdOutlineAccountBalanceWallet,
    link: "/user/recharge",
  },
  { name: "Withdraw", icon: FaWallet, link: "/user/withdraw" },
  {
    name: "Wallet info",
    icon: FaAddressCard,
    link: "/user/wallet-info",
  },
  // { name: "", icon: , link: "/user/" },
  {
    name: "About",
    icon: BsFillInfoCircleFill,
    link: "/user/about",
    isBelow: true,
  },
  {
    name: "Membership",
    icon: MdCardMembership,
    link: "/user/membership",
    isBelow: true,
  },
  {
    name: "Certificate",
    icon: PiCertificateFill,
    link: "/user/certificate",
    isBelow: true,
  },
  {
    name: "Get In Touch",
    icon: AiFillMessage,
    link: "/user/GetInTouch",
    isBelow: true,
  },
  {
    name: "Terms & Conditions",
    icon: AiFillMessage,
    link: "/user/T&C",
    isBelow: true,
  },
  {
    name: "FAQs",
    icon: AiFillMessage,
    link: "/user/faqs",
    isBelow: true,
  },
];

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      bg={"#100124"}
      h={"full"}
      overflowY="auto" // Enable vertical scrolling
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={Logo} />
        <CloseButton
          display={{ base: "flex", md: "none" }}
          color="white"
          onClick={onClose}
        />
      </Flex>

      {/* Scrollable Content */}
      <Box
        maxHeight="calc(100vh - 80px)"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "12px",
            borderRadius: "10px",
            backgroundColor: `#1a032e`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: `#71717a`,
            borderRadius: "12px",
          },
        }}
      >
        {LinkItems.map((link, i) => {
          if (!link.isBelow) {
            return (
              <Link
                to={link.link}
                key={i}
                onClick={onClose} // This will close the sidebar when a link is clicked
              >
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  color={"white"}
                  link={link.link}
                >
                  {window.location.pathname === link.link ? (
                    <Text color={"gray.900"} fontWeight={"4xl"}>
                      {link.name}
                    </Text>
                  ) : (
                    <Text color={"white"}>{link.name}</Text>
                  )}
                </NavItem>
              </Link>
            );
          }
        })}

        {/* Divider and spacing after Wallet info */}
        <Divider my={4} borderColor="gray.600" />

        {/* Section below Wallet info with additional spacing */}
        <Box mt={"70px"}>
          {LinkItems.filter((link) => link.isBelow).map((link, i) => (
            <Link
              to={link.link}
              key={i}
              onClick={onClose} // This will close the sidebar when a link is clicked
            >
              <NavItem
                key={link.name}
                icon={link.icon}
                color={"white"}
                link={link.link}
              >
                {window.location.pathname === link.link ? (
                  <Text color={"gray.900"} fontWeight={"4xl"}>
                    {link.name}
                  </Text>
                ) : (
                  <Text color={"white"}>{link.name}</Text>
                )}
              </NavItem>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, link, ...rest }) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      {window.location.pathname === link ? (
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          bg="yellow.500"
          color="blue.700"
          boxShadow={"50"}
          {...rest}
        >
          {icon && <Icon mr="4" fontSize="16" color="gray.900" as={icon} />}
          {children}
        </Flex>
      ) : (
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "gray.400",
            color: "gray.900",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "gray.900",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      )}
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  function signOut() {
    removeTawkTo();
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    window.location = "/";
  }
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      // bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
      bg={"#100124"}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
        color={"white"}
      />

      {/* <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text> */}

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <FaUserCircle color="white" size={"30px"} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                  color={"white"}
                >
                  <Text fontSize="sm">
                    {localStorage.getItem("userName") || ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown color="white" />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <Link to="/user/profile">
                <MenuItem>Profile</MenuItem>
              </Link>
              <MenuDivider />
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  return (
    // <Box bg={useColorModeValue("black.100", "gray.700")}>
    <Box bg={"gray.700"}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>{/* Content */}</Box>
    </Box>
  );
};

export default SidebarWithHeader;
