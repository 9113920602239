import { Card, CardBody, CardFooter, CardHeader, Heading, Text } from '@chakra-ui/react'
import React from 'react'

function RechargeCard({data}) {
  return (
    <Card variant={"elevated"} bg={'gray.300'} maxW={"250px"}>
      <CardHeader>
        <Heading color={"blue.900"} as={"h5"} size='md'> {localStorage.getItem("userName") || "elias543"}</Heading>
      </CardHeader>
      <CardBody mt={-8}>
        <Text fontWeight={"20px"} color={"blue.900"}>{`Amount : ${data.amount||0} USDC`}</Text>
        <Text fontWeight={"20px"} color={"blue.900"} mt={3}>{`Date : ${new Date(data?.createdAt).toLocaleDateString() ||'2024-06-27'}`}</Text>
      </CardBody>
    </Card>
  )
}

export default RechargeCard