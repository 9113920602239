import axiosInstance from "./config";

export const orderHistory = async () => {
  try {
    const res = await axiosInstance.get("/ratings/history");
    return res.data;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const rateOrder = async (comment,rating) => {
  try {
    const res = await axiosInstance.post("/ratings/rate",{comment,user_rating:rating});
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const buyOrder = async () => {
  try {
    const res = await axiosInstance.post("/ratings/buy",{});
    return res;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const generateOrder = async () => {
  try {
    const res = await axiosInstance.get("/ratings/generate",);
    return res;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const getOrderInfo = async () => {
  try {
    const res = await axiosInstance.get("/ratings/info");
    return res.data;
  } catch (error) {
    console.log(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

