import { Button } from '@chakra-ui/react'
import React from 'react'

function AmountCard({amount}) {
  return (
    <Button
    rounded={"20px"}
     w={"100%"}
    //size={"lg"}
    py={"0"}
    //px ={10}
    bg={"purple.700"}
    color={"gray.300"}
    textTransform={"uppercase"}
    boxShadow={10}
    _hover={{
      transform: "translateY(2px)",
      boxShadow: "lg",
      bg: `gray.300`,
      color: "blue.900",
    }}
  >
    {`${amount} USDC`}
  </Button>
  )
}

export default AmountCard