import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { renderTawkTo } from "../utils/tawkTo";

const axiosInstance = axios.create({
  baseURL: 'https://api.hollymovies.org/api', 
  // baseURL:  "http://localhost:5555/api", 
});

export const setAuthToken = (token, userName) => {
  if (token) {
    axiosInstance.defaults.headers.common["authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
    localStorage.setItem("userName", userName);
    renderTawkTo();
  }
  console.log(axiosInstance.defaults.headers.common["authorization"]);
};
export const isAuthToken = () => {
  const token = localStorage.getItem("token");
  try {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        localStorage.removeItem("token");
        localStorage.removeItem("userName");
        return false;
      }
      if (axiosInstance.defaults.headers.common?.authorization) {
        console.log(
          "Auth ",
          axiosInstance.defaults.headers.common?.authorization
        );
      } else if (token) {
        setAuthToken(
          localStorage.getItem("token"),
          localStorage.getItem("userName")
        );
      }
      return true;
    }
    return false;
  } catch (error) {
    console.error("Invalid token:", error.message);
    localStorage.removeItem("token"); // Remove the token if it's invalid
    return false;
  }
};

export default axiosInstance;
