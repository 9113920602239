import React from "react";
import MovieCard from "./movieCard";
import { Box, Heading, HStack, useColorModeValue } from "@chakra-ui/react";

function MovieListing({ data, heading }) {
  return (
    <>
      <Heading mt={10} color={useColorModeValue("yellow.500", "yellow.300")}>
        {heading}
      </Heading>
      <Box
        overflowX="auto"
        maxW={"100%"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "16px",
            height: "12px",
            borderRadius: "10px",
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray.600",
            borderRadius: "12px",
          },
        }}
      >
        <HStack align={"flex-start"}>
          {data.map((val, i) => (
            <MovieCard data={val} key={i} />
          ))}
        </HStack>
      </Box>
    </>
  );
}

export default MovieListing;
