import React, { useState } from "react";
import { Container, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import MainTabs from "./component/tabs";
import { withdrawalHistory } from "../../api/user";
function Index() {
  const toast = useToast();
  const [data,setData] = useState({})
  const [loading,setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true);
    let res
    try {
        withdrawalHistory('withdrawal').then(res=>{
          console.log(res)
        setData(res);
      }).catch(error=>{
          setLoading(false);
          toast({
            title: "Error While Loading Data",
            description: error.message,
            status: "error",
            position: "top",
          });
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error While Loading Data",
        description: error.message,
        status: "error",
        position: "top",
      });
    }
  },[])
  return (
    <Container alignContent={"flex-start"} maxW={"100%"} >
      {loading && <MainTabs data = {data} />}
    </Container>
  );
}

export default Index;
