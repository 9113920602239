"use client";

import React from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Image,
  Center,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import PremiumPic from "../../assets/premiumImg.png"
import ExpertPic from "../../assets/expert.png"
import MasterPic from "../../assets/master1.png"
import Beginner from "../../assets/beginner.png"

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl"  >
          Our Membership Plans
        </Heading>
        <Text
          fontSize="medium"
          fontWeight="300"
          color={"gray.300"}
          maxW={"2xl"}
        >
          Holly Movies Membership Upgrade for Master: There are additional
          benefits. If you’d like to learn more, please feel free to contact our
          customer service!
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={3}
        py={5}
      >

        <PriceWrapper>
          <Box py={4} px={5}>
            <Center>
              <Image src={PremiumPic} width="80px" ></Image>
            </Center>
            <Text fontWeight="500" fontSize="2xl" color={"gray.300"}>
              Elite
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900" color={"gray.300"}>
                1000
              </Text>
              <Text fontSize="3xl" fontWeight="600" color={"gray.300"}>
                (USDC)
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start">
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Boosting Days
                <Text fontSize="large" fontWeight="900" textAlign="Center">
                  50
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Commission
                <Text fontSize="large" fontWeight="900" textAlign="Center">
                  0.8% - 6%
                </Text>
              </ListItem>
            </List>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box py={4} px={5}>
              <Center>
                <Image src={ExpertPic} width="80px" ></Image>
              </Center>
              <Text fontWeight="500" fontSize="2xl" color={"gray.300"}>
                Expert
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="5xl" fontWeight="900" color={"gray.300"}>
                  2000
                </Text>
                <Text fontSize="3xl" fontWeight="600" color={"gray.300"}>
                  (USDC)
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Boosting Days
                  <Text fontSize="large" fontWeight="900" textAlign="Center">
                    60
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Commission
                  <Text fontSize="large" fontWeight="900" textAlign="Center">
                    0.9% - 7%
                  </Text>
                </ListItem>
              </List>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={5}>
            <Center>
              <Image src={MasterPic} width="80px" ></Image>
            </Center>
            <Text fontWeight="500" fontSize="2xl" color={"gray.300"}>
              Master
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900" color={"gray.300"}>
                4000
              </Text>
              <Text fontSize="3xl" fontWeight="600" color={"gray.300"}>
                (USDC)
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Boosting of days
                <Text fontSize="large" fontWeight="900" textAlign="Center">
                  70
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Commission
                <Text fontSize="large" fontWeight="900" textAlign="Center">
                  1% - 8%
                </Text>
              </ListItem>
            </List>
          </VStack>
        </PriceWrapper>
      </Stack>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start" paddingLeft="30px" paddingRight="30px">
        <Text as="p" fontSize="lg" color={"white"}>
          Offer are open to every Holly Movies members
          All deposits can be withdrawn included upgrade membership fees
          Once membership is activated there will be no extra charges upon
          Membership's privileges are lifetime
        </Text>
        <Text as="p" fontSize="medium" color={"white"}>
          Holly Movies  membership is NON-transferable·
        </Text>

        <Text as="p" fontSize="lg" color={"white"}>
          The profit rate will increase with your membership privilege level. (As shown in the above)
        </Text>
        <br />
        <Heading color={useColorModeValue("red.500", "red.300")} as="h6" fontSize={"medium"}>
          Note
        </Heading> <Text color={"white"}>In the event that any member violate our Terms and Conditions, Holly Movies always reserve the right to terminate the member's membership status without any prior notice
          <br />
        </Text>
      </VStack>
    </Box>

  );
}
