'use client'

import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react'



const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <Heading as="h1" color={'white'}>General FAQs</Heading>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        
      </Box>
      
      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: '100%', sm: '45%', md: '45%', lg: '80%' }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
              </Box>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
      <Heading as="h5" fontSize="large" color={'red'}>Important Notice</Heading>
      <Text as="p" fontSize="lg" color={'white'}>
      Members are strictly prohibited from registering multiple accounts and repeatedly using the same wallet address for enhance Movies. If such activity is detected, the account will be frozen by the system. For more information, please contact our customer service.
All forms of malicious money laundering are strictly prohibited. If any such activity is found, the platform will freeze the associated account and take necessary legal action.
        </Text>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">

        <Heading as="h5" fontSize="large" color={'white'}>Deposit</Heading>
        <Text as="p" fontSize="medium" color={'white'}>
        For each deposit, you are required to contact our customer service for assistance with the remittance deposit process. After remitting the funds to the account provided by the platform's customer service, please provide a screenshot of the successful transfer. To ensure instant deposit processing, ensure that the wallet address match the details provided. If you encounter any issues during the deposit process, please contact our deposit customer service promptly. Note that the platform occasionally changes wallet address, so please carefully check the wallet address before making a deposit. For any inquiries, kindly seek assistance from our live customer service.
        </Text>

        <Heading as="h5" fontSize="large" color={'white'}>Withdrawal</Heading>
        <Text as="p" fontSize="lg" color={'white'}>
        Before proceeding with a withdrawal, please ensure that you have bound your withdrawal address on the platform. To withdraw funds, go to the home page and click on the "Withdrawal" interface. Enter the desired withdrawal amount and your withdrawal password to initiate the withdrawal. Withdrawal processing time is within one hour, following the platform's operating hours.
        </Text>

        <Heading as="h5" fontSize="large" color={'white'}>Movies Set</Heading>
        <Text as="p" fontSize="lg" color={'white'}>
        When your account balance becomes negative, it means that you have received an Movies Set. Each Movies Set includes 1-3 Movies. The allocation of Movies Set is based on the account balance. In the case of a negative balance, please contact our customer service for assistance with the remittance deposit. As a reward for completing the Movies Set, your account will receive 5%-8% Commission.
        </Text>
        
        <Heading as="h5" fontSize="large" color={'white'}>Platform Agent Mode</Heading>
        <Text as="p" fontSize="lg" color={'white'}>
        Users of this platform have the opportunity to earn additional dynamic commissions by referring new users. The system will automatically grant you a 25% commission based on your down-line's earnings.
        </Text>

        <Heading as="h5" fontSize="large" color={'white'}>Operating Hours</Heading>
        <Text as="p" fontSize="lg" color={'white'}>
        The platform operates from 11:00 am to 11:00 pm CT . Users able to enhance Movies during the platform's operating hours.
        </Text>

        <Text as="p" fontSize="lg" color={'white'} marginTop={'30px'}>
        (Note: For further assistance, please contact our customer service. Thank you.）
        </Text>

      </VStack>
    </Container>
  )
}

export default ArticleList
