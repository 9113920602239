import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SidebarWithHeader from "../shared/navbar";
// import Footer from "../shared/footer/index";
import { Box, Text } from "@chakra-ui/react";
import { isAuthToken } from "../api/config";

function UserRootLayout() {
  return (
    <>
      {!isAuthToken() ? (
        <Navigate to="/" />
      ) : (
        <>
          <SidebarWithHeader />
          <Box
            pl={{ md: "30%", sm: "17%", xl: "17%", lg: "20%", base: "0" }}
            pr={{ md: "%", base: 0 }}
            bgGradient="linear(to-r, gray.900, purple.900)"
            minH={"100vh"}
          >
            <Outlet />
            {/* <Footer /> */}
            <Text p={10} as="p" fontSize="lg" color={"white"} marginTop={"30px"}>
              © 2024 HollyMovies.INC All rights reserved.
            </Text>
          </Box>
        </>
      )}
    </>
  );
}

export default UserRootLayout;
